import React from 'react'
import { Link } from 'gatsby'

import { Paragraph, P, H2 } from '@allied-solutions/affinity'

import { useDirectToFlow, useRedirectToEnterRefId } from '../../hooks'
import {
  AppLayout,
  ButtonGroup,
  ConfirmBorrowerInfo,
  SubmitInsuranceLayout,
} from '../../components'

const ConfirmBorrowerInfoPage = ({ location }) => {
  useRedirectToEnterRefId()
  const directToFlow = useDirectToFlow()

  return (
    <SubmitInsuranceLayout location={location}>
      <AppLayout
        leftSide={
          <>
            <Paragraph
              mb={6}
              typeStyle="labelMedium"
              textAlign="left"
              color="disabled"
            >
              Confirm
            </Paragraph>
            <H2 className="h4" typeStyle="h4">
              Here’s what we found. Does this look right?
            </H2>
            <Paragraph
              typeStyle="bodyMedium"
              textAlign="left"
              color="secondary"
            >
              This is what we have on file based on the information you
              provided.
              <br />
              <br />
              If this information is accurate, click “It’s correct” to continue.
              Otherwise, click “It’s incorrect” to make changes.
            </Paragraph>
          </>
        }
        rightSide={<ConfirmBorrowerInfo />}
        footerButtons={
          <ButtonGroup
            css={`
              @media only screen and (max-width: ${props => props.theme[1]}) {
                display: grid;
                direction: rtl;
              }
            `}
            display="flex"
            justifyContent="flex-end"
          >
            <ButtonGroup.Button
              id="NoRefId__ConfirmInfo__Button--incorrect"
              as={Link}
              to="/no-reference-id/lookup/not-found/"
              css={`
                white-space: nowrap;
              `}
            >
              <P
                mb={0}
                typeStyle="labelMedium"
                textAlign="right"
                color="darkGray"
              >
                It’s incorrect
              </P>
            </ButtonGroup.Button>
            <ButtonGroup.ButtonAnchor
              id="NoRefId__ConfirmInfo__Button--correct"
              minWidth={24}
              ml={['5', '8']}
              rounded
              forwardedAs={Link}
              to={directToFlow()}
            >
              It’s correct
            </ButtonGroup.ButtonAnchor>
          </ButtonGroup>
        }
      />
    </SubmitInsuranceLayout>
  )
}

export default ConfirmBorrowerInfoPage
